import React from "react";
import { Helmet } from "react-helmet";

function SEO() {
  return (
    <div className="application">
      <Helmet>
        <title>Wandry</title>
      </Helmet>
    </div>
  );
}

export default SEO;
