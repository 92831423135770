import React, { useState, useEffect, useRef } from "react";
import { Navbar, Nav, Card, CardDeck, Row, Col, Image } from "react-bootstrap";
import { isMobile, isIOS, isAndroid } from "react-device-detect";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../src/assets/main.css";
import Seo from "../components/Seo";
import Subscribe from "../components/Subscribe";
import Footer from "../components/Footer";
import Social from "../components/Social";

const IndexPage = () => {
  const [cardActive, setCardActive] = useState(1);
  const [navbarExpanded, setNavbarExpanded] = useState(false);

  const isBrowser = typeof window !== "undefined";

  function getScreenWidth() {
    if (isBrowser) {
      return window.innerWidth;
    }
    return 1080;
  }

  const [screenWidth, setScreenWidth] = useState(getScreenWidth());

  const handleShareBtnClick = () => {
    setNavbarExpanded(false);
    try {
      if (navigator?.share) {
        navigator.share({
          title: `Wandry`,
          text: `Check out Wandry!`,
          url: document.location,
        });
      }
    } catch (error) {
      console.error("Unable to share Wandry page", error);
    }
  };

  const popupEl = useRef(null);

  function handleCardClick(cardClicked) {
    if (cardClicked !== cardActive) {
      popupEl.current.style.display = "none";
      popupEl.current.style.animation = "fadeOut 1.5s";
      setCardActive(cardClicked);
      setTimeout(function () {
        popupEl.current.style.display = "flex";
        popupEl.current.style.animation = "fadeIn 1.5s";
      }, 0);
    }
  }

  useEffect(() => {
    if (isBrowser) {
      function handleResize() {
        setScreenWidth(getScreenWidth());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  return (
    isBrowser && (
      <>
        <Seo />
        <Navbar
          expand="lg"
          sticky="top"
          className="navBar"
          expanded={navbarExpanded}
          collapseOnSelect
        >
          <div className="container">
            <div className="navBarRight">
              <Navbar.Brand
                href="#hero"
                onClick={() => setNavbarExpanded(false)}
              >
                <img
                  src={
                    "https://wandry-landing-page-assets.s3.amazonaws.com/images/header/logo.png"
                  }
                  className="d-inline-block align-top"
                  alt="Wandry logo"
                />
              </Navbar.Brand>
              {navigator?.share && (
                <Nav.Link className="shareBtn" onClick={handleShareBtnClick}>
                  <img
                    src={
                      "https://wandry-landing-page-assets.s3.amazonaws.com/images/header/share-icon.svg"
                    }
                    className="d-inline-block shareIcon"
                    alt="Share"
                  />
                  Share
                </Nav.Link>
              )}
            </div>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              onClick={() => setNavbarExpanded(!navbarExpanded)}
            />
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-end"
            >
              <Nav>
                <Nav.Link
                  href="#why-wandry"
                  className="mx-4 navLink"
                  onClick={() => setNavbarExpanded(false)}
                >
                  Why Wandry?
                </Nav.Link>
                <hr className="mobileNavDivider" />
                <Nav.Link
                  href="#features"
                  className="mx-4 navLink"
                  onClick={() => setNavbarExpanded(false)}
                >
                  Features
                </Nav.Link>
                <hr className="mobileNavDivider" />
                <Nav.Link
                  href="#subscribe"
                  className="mx-4 subButton"
                  onClick={() => setNavbarExpanded(false)}
                >
                  Subscribe
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
        <div onClick={() => setNavbarExpanded(false)}>
          <div className="container relative">
            <div className="hero">
              <div id="hero" />
              <Row className="heroRow">
                <Col
                  className="heroContent"
                  data-sal="slide-down"
                  data-sal-duration="1000"
                  data-sal-delay="250"
                >
                  <h1 className="sectionTitle pageTitle">
                    Explore the World Well Informed
                  </h1>
                  <p className="heroText">
                    Trusted and verified travel data in one app
                  </p>
                  <a href="#take-a-look" className="lookButton">
                    Learn More
                  </a>
                  {/* download buttons removed while app is offline */}
                  {isMobile && isIOS && (
                    // <div>
                    //   <p className="download-text">Get the app:</p>
                    //   <a href="https://apps.apple.com/us/app/wandry-travel-planner/id1573615963#?platform=iphone">
                    //     <img
                    //       src="https://wandry-landing-page-assets.s3.amazonaws.com/images/hero/iOSDownloadButton.png"
                    //       className="ios-download-button"
                    //     />
                    //   </a>
                    // </div>
                    <Social />
                  )}
                  {/* download buttons removed while app is offline */}
                  {isMobile && isAndroid && (
                    // <div>
                    //   <p className="download-text">Get the app:</p>
                    //   <a href="https://play.google.com/store/apps/details?id=com.gem.wandryplanner">
                    //     <img
                    //       src="https://wandry-landing-page-assets.s3.amazonaws.com/images/hero/AndroidDownloadButton.png"
                    //       className="android-download-button"
                    //     />
                    //   </a>
                    // </div>
                    <Social />
                  )}
                </Col>
                <Col
                  data-sal="slide-down"
                  data-sal-duration="1000"
                  data-sal-delay="250"
                >
                  <Image
                    className="heroImage"
                    src={
                      "https://wandry-landing-page-assets.s3.amazonaws.com/images/hero/hero.jpg"
                    }
                    height={screenWidth > 860 ? 466 : screenWidth * 0.9}
                  />
                </Col>
              </Row>
            </div>
          </div>
          <div className="container relative">
            <div id="why-wandry" />
            <div id="take-a-look" />
            <h1
              className="sectionTitle"
              data-sal="slide-right"
              data-sal-duration="1000"
              data-sal-delay="250"
            >
              Why Wandry?
            </h1>
            <CardDeck className="cards-container">
              <div
                className="card-animator first-card"
                data-sal="slide-down"
                data-sal-duration="1000"
                data-sal-delay="250"
              >
                <Card
                  className={
                    cardActive === 1
                      ? "card relative cardActive"
                      : "card relative"
                  }
                  onClick={() => handleCardClick(1)}
                >
                  <Card.Img
                    variant="top"
                    src={
                      "https://wandry-landing-page-assets.s3.amazonaws.com/images/cards/wandry-app.png"
                    }
                  />
                  <div className="cardIconContainer">
                    <Card.Img
                      src={
                        "https://wandry-landing-page-assets.s3.amazonaws.com/images/cards/checkmark-icon.svg"
                      }
                      height={15}
                    />
                  </div>
                  <Card.Body className="cardBody">
                    <Card.Text className="cardText">
                      Get relevant &{" "}
                      <span className="cardSpan">verified travel data</span>
                      <br />
                      in one place
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
              <div
                className="card-animator"
                data-sal="slide-down"
                data-sal-duration="1000"
                data-sal-delay={screenWidth > 991 ? "600" : "0"}
              >
                <Card
                  className={
                    cardActive === 2
                      ? "card relative cardActive"
                      : "card relative"
                  }
                  onClick={() => handleCardClick(2)}
                >
                  <Card.Img
                    variant="top"
                    src={
                      "https://wandry-landing-page-assets.s3.amazonaws.com/images/cards/magnifying-glass.png"
                    }
                  />
                  <div className="cardIconContainer">
                    <Card.Img
                      src={
                        "https://wandry-landing-page-assets.s3.amazonaws.com/images/cards/eye-icon.svg"
                      }
                      height={20}
                    />
                  </div>
                  <Card.Body className="cardBody">
                    <Card.Text className="cardText">
                      <span className="cardSpan">Know before you go</span> to
                      enjoy a seamless travel experience
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
              <div
                className="card-animator"
                data-sal="slide-down"
                data-sal-duration="1000"
                data-sal-delay={screenWidth > 991 ? "800" : "0"}
              >
                <Card
                  className={
                    cardActive === 3
                      ? "card relative cardActive"
                      : "card relative"
                  }
                  onClick={() => handleCardClick(3)}
                >
                  <Card.Img
                    variant="top"
                    src={
                      "https://wandry-landing-page-assets.s3.amazonaws.com/images/cards/OK.png"
                    }
                  />
                  <div className="cardIconContainer">
                    <Card.Img
                      src={
                        "https://wandry-landing-page-assets.s3.amazonaws.com/images/cards/airplane-icon.svg"
                      }
                      height={20}
                    />
                  </div>
                  <Card.Body className="cardBody">
                    <Card.Text className="cardText">
                      Make travel decisions based on{" "}
                      <span className="cardSpan">accurate up-to-date data</span>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
              <div
                className="card-animator last-card"
                data-sal="slide-down"
                data-sal-duration="1000"
                data-sal-delay={screenWidth > 991 ? "1000" : "0"}
              >
                <Card
                  className={
                    cardActive === 4
                      ? "card relative cardActive"
                      : "card relative"
                  }
                  onClick={() => handleCardClick(4)}
                >
                  <Card.Img
                    variant="top"
                    src={
                      "https://wandry-landing-page-assets.s3.amazonaws.com/images/cards/airport.png"
                    }
                  />
                  <div className="cardIconContainer">
                    <Card.Img
                      src={
                        "https://wandry-landing-page-assets.s3.amazonaws.com/images/cards/mask-icon.svg"
                      }
                      height={20}
                    />
                  </div>
                  <Card.Body className="cardBody">
                    <Card.Text className="cardText">
                      <span className="cardSpan">
                        Stay safe
                        <br />
                        and informed
                      </span>
                      <br /> while traveling
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </CardDeck>
            <div
              data-sal="slide-right"
              data-sal-duration="1000"
              data-sal-delay="250"
            >
              <div className={`popup relative tooltip${cardActive}`}>
                <div className="popupInner" ref={popupEl}>
                  {cardActive === 1 ? (
                    <>
                      <Image
                        className="popupImage"
                        src={
                          screenWidth > 991
                            ? "https://wandry-landing-page-assets.s3.amazonaws.com/images/tooltips/BinocularsDesktop.png"
                            : "https://wandry-landing-page-assets.s3.amazonaws.com/images/tooltips/BinocularsMobile.png"
                        }
                        height={screenWidth > 991 ? 147 : 202}
                      />
                      <div className="popupContent">
                        <h2 className="popupTitle">What is Wandry?</h2>
                        <p className="popupText">
                          Wandry is a people-first mobile application intended
                          to bring peace of mind to travelers worldwide by
                          keeping them healthy and safe. By compiling relevant,
                          timely destination and travel information from
                          multiple sources, Wandry makes the essentials of
                          travel easier for you.
                        </p>
                      </div>
                    </>
                  ) : cardActive === 2 ? (
                    <>
                      <Image
                        className="popupImage"
                        src={
                          screenWidth > 991
                            ? "https://wandry-landing-page-assets.s3.amazonaws.com/images/tooltips/WatchDesktop.png"
                            : "https://wandry-landing-page-assets.s3.amazonaws.com/images/tooltips/WatchMobile.png"
                        }
                        height={screenWidth > 991 ? 147 : 202}
                      />
                      <div className="popupContent">
                        <h2 className="popupTitle">
                          Learn about travel rules and requirements before you
                          go
                        </h2>
                        <p className="popupText">
                          Wandry provides all the details you need to know to
                          prepare for your next vacation. Get information about
                          restrictions, requirements, and mandates for your
                          destination before you travel.
                        </p>
                      </div>
                    </>
                  ) : cardActive === 3 ? (
                    <div className="popupInner">
                      <Image
                        className="popupImage"
                        src={
                          screenWidth > 991
                            ? "https://wandry-landing-page-assets.s3.amazonaws.com/images/tooltips/ReticleDesktop.png"
                            : "https://wandry-landing-page-assets.s3.amazonaws.com/images/tooltips/ReticleMobile.png"
                        }
                        height={screenWidth > 991 ? 147 : 202}
                      />
                      <div className="popupContent">
                        <h2 className="popupTitle">How accurate is Wandry?</h2>
                        <p className="popupText">
                          Wandry sources official government data from around
                          the world to ensure accuracy for planning safe travel.
                          We are committed to sourcing and delivering data from
                          the most reliable and trusted agencies, providing
                          travelers with a high level of confidence in their
                          travel decisions.
                        </p>
                      </div>
                    </div>
                  ) : cardActive === 4 ? (
                    <div className="popupInner">
                      <Image
                        className="popupImage"
                        src={
                          screenWidth > 991
                            ? "https://wandry-landing-page-assets.s3.amazonaws.com/images/tooltips/LowRiskLevelDesktop.png"
                            : "https://wandry-landing-page-assets.s3.amazonaws.com/images/tooltips/LowRiskLevelMobile.png"
                        }
                        height={screenWidth > 991 ? 147 : 202}
                      />
                      <div className="popupContent">
                        <h2 className="popupTitle">
                          How can Wandry help me stay safe and informed?
                        </h2>
                        <p className="popupText">
                          Remaining safe means understanding severe weather can
                          happen at any time. Wandry monitors conditions and
                          alerts travelers to any severe changes in weather that
                          could affect their trip. Wandry also provides
                          safety-related observations from online traveler
                          reviews so that you can learn about precautions
                          businesses are practicing.
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="container relative">
            <div id="features" />
            <h1
              className="sectionTitle"
              data-sal="slide-right"
              data-sal-duration="1000"
              data-sal-delay="250"
            >
              Features
            </h1>
            <Row className="featureContainer columnReverseMobile">
              <Col
                data-sal="slide-right"
                data-sal-duration="1000"
                data-sal-delay="250"
              >
                <h2 className="featureSubtitle">Travel Specifications</h2>
                <p className="featureText">
                  Wandry lets you know about any rules and requirements that you
                  can expect to encounter while traveling.
                </p>
              </Col>
              <Col
                data-sal="slide-down"
                data-sal-duration="1000"
                data-sal-delay="250"
              >
                <Image
                  className="featureImage"
                  src={
                    "https://wandry-landing-page-assets.s3.amazonaws.com/images/features/TravelSpecifications.png"
                  }
                  height={screenWidth > 576 ? 500 : screenWidth * 0.9}
                />
              </Col>
            </Row>
            <Row className="featureContainer columnMobile">
              <Col
                data-sal="slide-down"
                data-sal-duration="1000"
                data-sal-delay="250"
              >
                <Image
                  className="featureImage"
                  src={
                    "https://wandry-landing-page-assets.s3.amazonaws.com/images/features/Prepare.png"
                  }
                  height={screenWidth > 576 ? 500 : screenWidth * 0.9}
                />
              </Col>
              <Col
                data-sal="slide-left"
                data-sal-duration="1000"
                data-sal-delay="250"
              >
                <h2 className="featureSubtitle">Prepare</h2>
                <p className="featureText">
                  Get relevant data on the destinations you want to visit:{" "}
                </p>
                <ul className="featureList">
                  <li>Authorization Forms</li>
                  <li>Vaccine, Covid Test, and Quarantine Requirements</li>
                  <li>Mask Mandates</li>
                  <li>Local Restrictions</li>
                  <li>Weather Alerts</li>
                </ul>
              </Col>
            </Row>
            <Row className="featureContainer columnReverseMobile">
              <Col
                data-sal="slide-right"
                data-sal-duration="1000"
                data-sal-delay="250"
              >
                <h2 className="featureSubtitle">
                  Discover the places you want to visit
                </h2>
                <p className="featureText">
                  Learn about points of interest such as lodging, restaurants,
                  shops, and more.
                </p>
              </Col>
              <Col
                data-sal="slide-down"
                data-sal-duration="1000"
                data-sal-delay="250"
              >
                <Image
                  className="featureImage"
                  src={
                    "https://wandry-landing-page-assets.s3.amazonaws.com/images/features/DiscoverDestinations.png"
                  }
                  height={screenWidth > 576 ? 500 : screenWidth * 0.9}
                />
              </Col>
            </Row>
          </div>
          <div className="subscribe">
            <div id="subscribe" />
            <Subscribe />
          </div>
          <div>
            {!isMobile && <Social />}

            <Footer />
          </div>
        </div>
      </>
    )
  );
};

export default IndexPage;
