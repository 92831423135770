import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "gatsby";
import "../../src/assets/main.css";

function Footer() {
  return (
    <>
      <div id="footer">
        <Container>
          <Row>
            <Col md={6} xs={12} className="pt-4 footer">
              <Image
                src={
                  "https://wandry-landing-page-assets.s3.amazonaws.com/images/footer/logo_white.png"
                }
                height={54}
              />
            </Col>
            <Col className="pt-5 d-flex flex-column align-items-end footer info-links">
              <Row>
                <Link className="footer-links" to="/privacy-policy/">
                  Privacy Policy
                </Link>
              </Row>
              <Row>
                <Link className="footer-links" to="/ios-terms-and-conditions/">
                  iOS Terms and Conditions
                </Link>
              </Row>
              <Row>
                <Link className="footer-links" to="/cookie-policy/">
                  Cookie Policy
                </Link>
              </Row>
              <Row>
                <a className="footer-links" href="mailto:contact@wandry.app">
                  Contact Us
                </a>
              </Row>
            </Col>
          </Row>
          <Row className="footer">
            <p>
              ©2021 Wandry. A product of{" "}
              <a target="_blank" href="https://www.gemax.io/">
                GEM
              </a>{" "}
              and{" "}
              <a target="_blank" href="https://lmsonline.com/">
                LMS, Inc.
              </a>
            </p>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Footer;
