import React from "react";
import { Image, Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../src/assets/main.css";
import Mailchimp from "react-mailchimp-form";

const Subscribe = () => {
  return (
    <Container>
      <Row>
        <Col className="subscribe-text text-center">
          Stay up to date with Wandry by subscribing to our newsletter.
        </Col>
      </Row>
      <Row>
        <div
          className="subscribe-box pt-3 pb-3"
          data-sal="slide-down"
          data-sal-duration="1000"
          data-sal-delay="300"
        >
          <Image
            src={
              "https://wandry-landing-page-assets.s3.amazonaws.com/images/subscribe/Subscribe-icon.svg"
            }
            className="subscribe-icon"
          />
          <Mailchimp
            action="https://lmsonline.us10.list-manage.com/subscribe/post?u=1194b5fd4dbb58116246d90f4&amp;id=03ba6d40a4"
            fields={[
              {
                name: "EMAIL",
                placeholder: "Email",
                type: "email",
                required: true,
              },
            ]}
            messages={{
              sending: "Sending...",
              success: "Thank you for subscribing!",
              error: "An unexpected internal error has occurred.",
              empty: "Please enter an email address.",
              duplicate: "You're already subscribed!",
              button: "Subscribe",
            }}
            className="subscribeButton"
          />
        </div>
      </Row>
    </Container>
  );
};

export default Subscribe;
