import React from "react";
import { Image, Container, Row, Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../src/assets/main.css";

function Social() {
  return (
    <Container className="socials-container">
      <Row className="flex-column-reverse flex-md-row">
        <Col
          className="d-flex flex-column align-items-center"
          data-sal="slide-down"
          data-sal-duration="1000"
          data-sal-delay="300"
        >
          {!isMobile && (
            <Image
              className="social-image center"
              src={
                "https://wandry-landing-page-assets.s3.amazonaws.com/images/socials/Social-media.png"
              }
              height={251}
            />
          )}
        </Col>
        <Col
          className="social-content"
          xs={10}
          sm={6}
          data-sal="slide-down"
          data-sal-duration="1000"
          data-sal-delay="300"
        >
          <Row className="social-text">Follow us on social media.</Row>
          <div className="social-icons-container">
            <a
              className="social-icon"
              href="https://www.instagram.com/wandryapp/"
            >
              <Image
                src={
                  "https://wandry-landing-page-assets.s3.amazonaws.com/images/socials/instagram.svg"
                }
                height={35}
              />
            </a>
            <a
              className="social-icon"
              href="https://www.facebook.com/wandryapp/"
            >
              <Image
                src={
                  "https://wandry-landing-page-assets.s3.amazonaws.com/images/socials/facebook.svg"
                }
                height={35}
              />
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Social;
